<script lang="ts">
  // Init client side in HTML-head.
  import {
    promo,
    user,
    userDoc,
    inviteCode,
    deepLink,
  } from "@/lib/client/stores";
  import { paddle } from "@/lib/client/paddle";
  import { auth } from "@/lib/client/firebase";
  import { onAuthStateChanged, type User } from "firebase/auth";
  import {
    getDoc,
    serverTimestamp,
    setDoc,
    updateDoc,
  } from "firebase/firestore";
  import { trackOnAuth, trackSignup } from "@/lib/client/analytics";
  import { getUserDoc } from "@/lib/client/db";
  import { onMount } from "svelte";
  import { getDaysFrom, getMidnight } from "@/lib/utils/date";
  import { getCurrentLocale } from "@/lib/utils/i18n";
  import { Capacitor } from "@capacitor/core";
  import { SocialLogin } from "@capgo/capacitor-social-login";
  import { App } from "@capacitor/app";
  import { particlesInit } from "@tsparticles/svelte";
  import { loadSlim } from "@tsparticles/slim";
  import { loadSnowPreset } from "@tsparticles/preset-snow";

  // particles
  onMount(() =>
    particlesInit(async (engine) => {
      await loadSnowPreset(engine);
      await loadSlim(engine);
    }),
  );

  // Capacitor
  onMount(() => {
    const platform = Capacitor.getPlatform();
    console.debug("cap platform:", platform);
  });

  // native login with apple and google
  onMount(async () => {
    switch (Capacitor.getPlatform()) {
      case "ios":
        await SocialLogin.initialize({
          apple: {},
          google: {
            iOSClientId:
              import.meta.env.PUBLIC_VERCEL_ENV === "production"
                ? "594411330224-f4gkttrk9153nug8alvrhnjioeuiulvu.apps.googleusercontent.com"
                : "915756183748-d1ucbm8u126con8a8e5oen842fj4pffm.apps.googleusercontent.com",
          },
        });
        break;
      case "android":
        await SocialLogin.initialize({
          apple: {
            clientId: "dev.5sides.zenstudy.signin",
            redirectUrl: "https://www.zenstudy.app/__/auth/handler",
          },
          google: {
            webClientId:
              import.meta.env.PUBLIC_VERCEL_ENV === "production"
                ? "594411330224-im5qpkmn7pe0p4anjm7u281jj849mdm1.apps.googleusercontent.com"
                : "915756183748-hd1mfd260iv0mgitjjta0m2oa798s16g.apps.googleusercontent.com",
          },
        });
        break;
      case "web":
        break;
      default:
        break;
    }
  });

  // app events
  onMount(() => {
    if (!Capacitor.isNativePlatform()) return;

    App.addListener("appStateChange", (state) => {
      console.debug("CapacitorApp.appStateChange", state);
    });

    App.addListener("appRestoredResult", (event) => {
      console.debug("CapacitorApp.appRestoredResult", event);
    });

    App.addListener("appUrlOpen", (event) => {
      console.debug("CapacitorApp.appUrlOpen", event);

      // parse deep link from event
      try {
        deepLink.set(new URL(event.url));
      } catch (err) {
        console.error("CapacitorApp.appUrlOpen - Error parsing url:", err);
      }
    });

    App.addListener("backButton", (event) => {
      console.debug("CapacitorApp.backButton", event);
    });

    console.debug("cap - setup app events");
  });

  // handle location and deep links
  onMount(() => {
    // initial location
    locationHandler();

    // listen for changes
    window.addEventListener("hashchange", () => locationHandler());
    window.addEventListener("popstate", () => locationHandler());
    return () => {
      window.removeEventListener("hashchange", () => locationHandler());
      window.removeEventListener("popstate", () => locationHandler());
    };
  });
  $: if ($deepLink) locationHandler($deepLink);
  function locationHandler(link: Location | URL = window.location) {
    console.debug("locationHandler", link);
    const params = new URLSearchParams(link.search);

    // promo
    if (params.has("promo")) {
      promo.set(params.get("promo")!);
      deepLink.set(null);
    }

    // sangha invite code
    if (params.has("x")) {
      inviteCode.set(params.get("x")!);
      deepLink.set(null);
    } else {
      const match = link.pathname.match(/\/x\/(\w+)/);
      if (match) {
        inviteCode.set(match[1]);
        deepLink.set(null);
      }
    }
  }

  // auth state change
  onMount(() => onAuthStateChanged(auth(), authStateChanged));
  async function authStateChanged(u: User | null) {
    console.debug("authStateChanged - user:", u);
    user.set(u);
    trackOnAuth(u);

    // logged in
    if (u) {
      try {
        // get user doc
        const ref = getUserDoc(u.uid);
        const doc = (await getDoc(ref)).data();

        // new user
        const isNewUser = doc === undefined;
        if (isNewUser) trackSignup(u);

        // streak reset
        const shouldResetStreak =
          doc && doc.lastLessonWonAt && getDaysFrom(doc.lastLessonWonAt) > 1;
        const streakStartAt =
          !doc?.streakStartAt || shouldResetStreak
            ? getMidnight()
            : doc.streakStartAt;

        // update user doc
        await setDoc(
          ref,
          {
            id: u.uid,
            email: u.email,
            lastActiveAt: serverTimestamp(),
            streakStartAt,
            language: getCurrentLocale(),
          },
          { merge: true },
        );
      } catch (err) {
        console.error(err);
      }
    }
  }

  $: if (!import.meta.env.SSR) {
    // promo
    if ($promo && $userDoc) {
      if ($promo.toUpperCase() === "LFSPWYBZJZ") {
        // TODO this should be done server-side
        updateDoc(getUserDoc($userDoc.id), { status: "active", promo: $promo });
        console.debug("promo activated", $promo);
      } else {
        console.warn("promo invalid", $promo);
      }
      promo.set(undefined);
    }

    // paddle
    if ($paddle && $user) {
      $paddle.Update({
        pwCustomer: {
          id: $userDoc?.customerId,
          email: $user.email ?? undefined,
        },
      });
    }

    // trigger userDoc subscription
    if ($userDoc) console.debug("update");
  }
</script>
